import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../components/shared/Loader";
import { updateMeta } from "../store/actions/metaAction";

const About = () => {
	const auth = useSelector(state => state.auth.user);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [subtitle, setSubtitle] = useState("");
	const [subtitleEn, setSubtitleEn] = useState("");
	const [text, setText] = useState("");
	const [textEn, setTextEn] = useState("");
	const [allInformation, setAllInformation] = useState([]);
	const [allInformationEn, setAllInformationEn] = useState([]);
	const [infoTitle, setInfoTitle] = useState(null);
	const [infoTitleEn, setInfoTitleEn] = useState(null);
	const [infoDescription, setInfoDescription] = useState(null);
	const [infoDescriptionEn, setInfoDescriptionEn] = useState(null);

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "من نحن",
				breadcrumb: "من نحن",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/98`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setTitle(response.data.data.data.title.ar)
				setTitleEn(response.data.data.data.title.en)
				setSubtitle(response.data.data.data.subtitle.ar)
				setSubtitleEn(response.data.data.data.subtitle.en)
				setText(response.data.data.data.text.ar)
				setTextEn(response.data.data.data.text.en)
				setAllInformation(response.data.data.data.information.ar)
				setAllInformationEn(response.data.data.data.information.en)
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const pushToInformation = e => {
		e.preventDefault();
		if (infoTitle === "") {
			toast.error("برجاء تعبئة الحقول المطلوبة!");
			return;
		}
		const data = { title: infoTitle, description: infoDescription };
		allInformation.push(data);
		setAllInformation(allInformation);
		setInfoTitle("");
		setInfoDescription("");
	};

	const handleDeleteInfo = (event, information) => {
		event.preventDefault();
		const arr = allInformation.filter(function (item) {
			return item.title !== information.title;
		});
		setAllInformation(arr);
	};

	const pushToInformationEn = e => {
		e.preventDefault();
		if (infoTitleEn === "") {
			toast.error("برجاء تعبئة الحقول المطلوبة!");
			return;
		}
		const data = { title: infoTitleEn, description: infoDescriptionEn };
		allInformationEn.push(data);
		setAllInformationEn(allInformationEn);
		setInfoTitleEn("");
		setInfoDescriptionEn("");
	};

	const handleDeleteInfoEn = (event, information) => {
		event.preventDefault();
		const arr = allInformationEn.filter(function (item) {
			return item.title !== information.title;
		});
		setAllInformationEn(arr);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		const data = {
			title: {
				ar: title,
				en: titleEn
			},
			subtitle: {
				ar: subtitle,
				en: subtitleEn
			},
			text: {
				ar: text,
				en: textEn
			},
			information: {
				ar: allInformation,
				en: allInformationEn
			},
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/98`,
				{
					admin_id: auth.id,
					category_id: 1,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث صفحة من نحن بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-header">البيانات الأساسية</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">العنوان الرئيسي بالعربية</label>
											<input
												type="text"
												id="name"
												required
												value={title}
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">العنوان الرئيسي بالانجليزية</label>
											<input
												type="text"
												id="name"
												required
												value={titleEn}
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="subtitle">العنوان الفرعي بالعربية</label>
											<input
												type="text"
												id="subtitle"
												required
												value={subtitle}
												onChange={e => setSubtitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="subtitle">
												العنوان الفرعي بالانجليزية
											</label>
											<input
												type="text"
												id="subtitle"
												required
												value={subtitleEn}
												onChange={e => setSubtitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="section1Text">النص بالعربية</label>
											<textarea
												rows="3"
												value={text}
												onChange={e => setText(e.target.value)}
												required
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="section1Text">النص بالانجليزية</label>
											<textarea
												rows="3"
												value={textEn}
												onChange={e => setTextEn(e.target.value)}
												required
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card mt-5">
							<div className="card-header">عن الشركة بالعربية</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<div className="row">
												<div className="col-md-12">
													<label htmlFor="question">عنوان البيان</label>
													<input
														type="text"
														id="question"
														value={infoTitle}
														onChange={e => setInfoTitle(e.target.value)}
													/>
												</div>
												<div className="col-md-12 mt-4">
													<label htmlFor="question">وصف البيان</label>
													<textarea
														id="question"
														value={infoDescription}
														onChange={e => setInfoDescription(e.target.value)}
													/>
												</div>
											</div>
										</div>
										<div className="form-item" />
										<button
											className="bt bt-success"
											onClick={pushToInformation}
										>
											اضافة البيان
										</button>
										<div className="form-item" style={{ marginTop: "40px" }}>
											<ul className="list-unstyled pointList">
												{allInformation.map(feature => (
													<li key={feature.title}>
														<button onClick={e => handleDeleteInfo(e, feature)}>
															<i className="fas fa-trash" />
														</button>
														<span>
															<strong>{feature.title}</strong> -{" "}
															{feature.description}
														</span>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card mt-5">
							<div className="card-header">عن الشركة بالانجليزية</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<div className="row">
												<div className="col-md-12">
													<label htmlFor="question">عنوان البيان</label>
													<input
														type="text"
														id="question"
														value={infoTitleEn}
														onChange={e => setInfoTitleEn(e.target.value)}
													/>
												</div>
												<div className="col-md-12 mt-4">
													<label htmlFor="question">وصف البيان</label>
													<textarea
														id="question"
														value={infoDescriptionEn}
														onChange={e => setInfoDescriptionEn(e.target.value)}
													/>
												</div>
											</div>
										</div>
										<div className="form-item" />
										<button
											className="bt bt-success"
											onClick={pushToInformationEn}
										>
											اضافة البيان
										</button>
										<div className="form-item" style={{ marginTop: "40px" }}>
											<ul className="list-unstyled pointList">
												{allInformationEn.map(feature => (
													<li key={feature.title}>
														<button onClick={e => handleDeleteInfoEn(e, feature)}>
															<i className="fas fa-trash" />
														</button>
														<span>
															<strong>{feature.title}</strong> -{" "}
															{feature.description}
														</span>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-item mt-3">
									<button className="bt bt-success btn-block">ارسال</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default About;