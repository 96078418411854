import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const PartnersIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع الشركاء",
				breadcrumb: "الشركاء",
				button: {
					permissions: ["post-create"],
					show: true,
					text: "اضافة شريك جديد",
					link: "/partners/create",
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/partners/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setData(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
				setLoading(false);
			});
	};

	const handleDelete = postID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/posts/${postID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="row">
				{data.map(item =>
					item.categories.map(category =>
						category.posts.map(post => (
							<div key={post.id} className="col-md-2">
								<div
									className="employees"
									style={{
										height: "150px",
										border: "1px solid #eee",
									}}
								>
									<button onClick={() => handleDelete(post.id)}>
										<i className="fas fa-trash"></i>
									</button>
									<Link to={`/partners/show/${post.id}`}>
										<img
											src={`${process.env.REACT_APP_MAIN_URL}${post.data.image}`}
											alt={post.data.image}
											style={{ objectFit: "fill" }}
										/>
									</Link>
								</div>
							</div>
						))
					)
				)}
			</div>
		</section>
	);
};

export default PartnersIndex;
