import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const QuestionsIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "الأسئلة الشائعة",
				breadcrumb: "الأسئلة الشائعة",
				button: {
					show: true,
					text: "اضافة سؤال جديد",
					link: "/questions/create",
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/popular_questions/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setData(response.data.data[0].categories[0].posts);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
				setLoading(false);
			});
	};

	const handleDelete = postID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/posts/${postID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	console.log(data);

	return (
		<section>
			{loading && <Loader />}
			{data?.map(item => (
				<div className="opinion" key={item.id}>
					<Link to={`/questions/show/${item.id}`}>
						<strong>{item.data.question.ar}:</strong>
						<br />
						{item.data.answer.ar}
					</Link>
					<button onClick={() => handleDelete(item.id)}>
						<i className="fas fa-trash" />
					</button>
				</div>
			))}
		</section>
	);
};

export default QuestionsIndex;
