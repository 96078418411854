import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";

const QuestionsShow = ({ match }) => {
	const {
		params: { opinionID },
	} = match;

	const auth = useSelector(state => state.auth.user);
	const [question, setQuestion] = useState("");
	const [questionEn, setQuestionEn] = useState("");
	const [answer, setAnswer] = useState("");
	const [answerEn, setAnswerEn] = useState("");
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل سؤال",
				breadcrumb: "الأسئلة الشائعة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${opinionID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setQuestion(response.data.data.data.question.ar);
				setQuestionEn(response.data.data.data.question.en);
				setAnswer(response.data.data.data.answer.ar);
				setAnswerEn(response.data.data.data.answer.en);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		const data = {
			question: {
				ar: question,
				en: questionEn
			},
			answer: {
				ar: answer,
				en: answerEn
			}
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${opinionID}`,
				{
					admin_id: auth.id,
					category_id: 38,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث الرأي بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="name">السؤال بالعربية</label>
											<input
												type="text"
												id="name"
												required
												value={question}
												onChange={e => setQuestion(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="name">السؤال بالانجليزية</label>
											<input
												type="text"
												id="name"
												required
												value={questionEn}
												onChange={e => setQuestionEn(e.target.value)}
											/>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="position">الإجابة بالعربية</label>
											<textarea
												id="position"
												cols="30"
												rows="2"
												value={answer}
												onChange={e => setAnswer(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="position">الإجابة بالإنجليزية</label>
											<textarea
												id="position"
												cols="30"
												rows="2"
												value={answerEn}
												onChange={e => setAnswerEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="form-item mt-3">
									<button className="bt bt-success btn-block">ارسال</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default QuestionsShow;
