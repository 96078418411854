import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userPlaceholder from "../assets/images/placeholder.jpg";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../components/shared/Loader";
import { updateMeta } from "../store/actions/metaAction";

const Ceo = () => {
	const auth = useSelector(state => state.auth.user);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [subtitle, setSubtitle] = useState("");
	const [subtitleEn, setSubtitleEn] = useState("");
	const [text, setText] = useState("");
	const [textEn, setTextEn] = useState("");
	const [link, setLink] = useState("");
	const [linkEn, setLinkEn] = useState("");
	const [url, setUrl] = useState("");
	const [years, setYears] = useState(0);
	const [mainPicture, setMainPicture] = useState(userPlaceholder);

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "رسالة الرئيس التنفيذي",
				breadcrumb: "رسالة الرئيس التنفيذي",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/97`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setMainPicture(response.data.data.data.mainPicture);
				setTitle(response.data.data.data.title.ar);
				setTitleEn(response.data.data.data.title.en);
				setSubtitle(response.data.data.data.subtitle.ar);
				setSubtitleEn(response.data.data.data.subtitle.en);
				setText(response.data.data.data.text.ar);
				setTextEn(response.data.data.data.text.en);
				setLink(response.data.data.data.linkName.ar);
				setLinkEn(response.data.data.data.linkName.en);
				setUrl(response.data.data.data.url);
				setYears(response.data.data.data.years);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleMainPicture = e => {
		setLoading(true);
		const fileInput = document.querySelector("#mainPicture");
		setMainPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setMainPicture(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		const data = {
			title: {
				ar: title,
				en: titleEn
			},
			subtitle: {
				ar: subtitle,
				en: subtitleEn
			},
			text: {
				ar: text,
				en: textEn
			},
			linkName: {
				ar: link,
				en: linkEn
			},
			url,
			years,
			mainPicture,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/97`,
				{
					admin_id: auth.id,
					category_id: 1,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث صفحة من نحن بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-header">البيانات الأساسية</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label>العنوان الرئيسي بالعربية</label>
											<input
												type="text"
												id="name"
												required
												value={title}
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label>العنوان الرئيسي بالإنجليزية</label>
											<input
												type="text"
												id="name-en"
												required
												value={titleEn}
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label>العنوان الفرعي بالعربية</label>
											<input
												type="text"
												id="subtitle"
												required
												value={subtitle}
												onChange={e => setSubtitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label>العنوان الفرعي بالانجليزية</label>
											<input
												type="text"
												id="subtitle-en"
												required
												value={subtitleEn}
												onChange={e => setSubtitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label>عنوان الزر بالعربية</label>
											<input
												type="text"
												id="link"
												required
												value={link}
												onChange={e => setLink(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label>عنوان الزر بالانجليزية</label>
											<input
												type="text"
												id="link-en"
												required
												value={linkEn}
												onChange={e => setLinkEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label>الرابط</label>
											<input
												type="text"
												id="link-url"
												required
												value={url}
												onChange={e => setUrl(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label>النص بالعربية</label>
											<textarea
												rows="3"
												value={text}
												onChange={e => setText(e.target.value)}
												required
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label>النص بالنجليزية</label>
											<textarea
												rows="3"
												value={textEn}
												onChange={e => setTextEn(e.target.value)}
												required
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label>سنين الخبرة</label>
											<input
												type="number"
												id="years-of-exp"
												required
												value={years}
												onChange={e => setYears(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input
										type="file"
										onChange={(e) => handleMainPicture(e)}
										id="mainPicture"
									/>
									<img
										src={`${process.env.REACT_APP_MAIN_URL}${mainPicture}`}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default Ceo;
