import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";

const OpinionsShow = ({ match }) => {
	const {
		params: { slideID },
	} = match;

	const auth = useSelector(state => state.auth.user);
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [url, setUrl] = useState("");
	const [urlTitle, setUrlTitle] = useState("");
	const [urlTitleEn, setUrlTitleEn] = useState("");
	const [description, setDescription] = useState("");
	const [descriptionEn, setDescriptionEn] = useState("");
	const [loading, setLoading] = useState(true);
	const [picture, setPicture] = useState(userPlaceholder);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل السلايد",
				breadcrumb: "سلايدر",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${slideID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setTitle(response.data.data.data.title.ar);
				setTitleEn(response.data.data.data.title.en);
				setDescription(response.data.data.data.description.ar);
				setDescriptionEn(response.data.data.data.description.en);
				setUrlTitle(response.data.data.data.urlTitle.ar);
				setUrlTitleEn(response.data.data.data.urlTitle.en);
				setUrl(response.data.data.data.url);
				setPicture(response.data.data.data.image);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = () => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		const data = {
			title: {
				ar: title,
				en: titleEn
			},
			description: {
				ar: description,
				en: descriptionEn
			},
			urlTitle: {
				ar: urlTitle,
				en: urlTitleEn
			},
			image: picture,
			url,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${slideID}`,
				{
					admin_id: auth.id,
					category_id: 16,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث السلايد بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">العنوان بالعربية</label>
											<input
												type="text"
												id="title"
												value={title}
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">العنوان بالإنجليزية</label>
											<input
												type="text"
												id="title-en"
												value={titleEn}
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="description">الرابط</label>
											<input
												type="url"
												id="url"
												value={url}
												onChange={e => setUrl(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="description">عنوان الرابط بالعربية</label>
											<input
												type="text"
												id="link-title"
												value={urlTitle}
												onChange={e => setUrlTitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="description">عنوان الرابط بالانجليزية</label>
											<input
												type="text"
												id="link-title-en"
												value={urlTitleEn}
												onChange={e => setUrlTitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="description">الوصف بالعربية</label>
											<textarea
												id="description"
												cols="30"
												rows="2"
												value={description}
												onChange={e => setDescription(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="description">الوصف بالإجليزية</label>
											<textarea
												id="description-en"
												cols="30"
												rows="2"
												value={descriptionEn}
												onChange={e => setDescriptionEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="picture" />
									<img
										src={`${process.env.REACT_APP_MAIN_URL}${picture}`}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default OpinionsShow;
