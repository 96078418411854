import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const SliderIndex = () => {
    const [slides, setSlides] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        onStart();
    }, []);

    const onStart = () => {
        dispatch(
            updateMeta({
                title: "جميع السلايدات",
                breadcrumb: "سلايدر",
                button: {
                    show: true,
                    text: "اضافة سلايد جديد",
                    link: "/slider/create",
                },
            })
        );
        axios
            .get(`${process.env.REACT_APP_API_URL}/posts/sliders/all`, {
                headers: {
                    Authorization: `Bearer ${cookie.get("token")}`,
                    Accept: "application/json",
                },
            })
            .then(function (response) {
                setSlides(response.data.data[0].categories[0].posts);
                setLoading(false);
            })
            .catch(function (error) {
                toast.error("خطأ، برجاء المحاولة لاحقا!");
            });
    };

    const columns = [
        {
            name: "ID",
            selector: "id",
            sortable: true,
        },
        {
            name: "الصورة",
            sortable: true,
            cell: row => {
                return <img style={{maxHeight: "100px", maxWidth: "200px"}} src={process.env.REACT_APP_MAIN_URL + row.data.image} alt={row.data.title}/>
            }
        },
        {
            name: "العنوان",
            sortable: true,
            cell: row => {
                return row.data.title.ar
            }
        },
        {
            name: "",
            selector: "settings",
            cell: row => (
                <ul className="list-inline settingsList">
                    <li className="list-inline-item">
                        <Link className="bt bt-sm bt-success" to={`/slider/show/${row.id}`}>
                            <i className="fas fa-edit"></i>
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        <button
                            className="bt bt-sm bt-danger"
                            onClick={() => handleConfirmDelete(row.id)}
                        >
                            <i className="fas fa-trash"/>
                        </button>
                    </li>
                </ul>
            ),
        },
    ];

    const handleConfirmDelete = slideID => {
        confirmAlert({
            title: "تأكيد الحذف",
            message: "هل أنت متأكد من رغبتك في الحذف؟",
            buttons: [
                {
                    label: "نعم",
                    onClick: () => deleteSlide(slideID),
                },
                {
                    label: "لا",
                    onClick: () => console.log("No"),
                },
            ],
        });
    };

    const deleteSlide = slideID => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/posts/${slideID}`, {
                headers: {
                    Authorization: `Bearer ${cookie.get("token")}`,
                    Accept: "application/json",
                },
            })
            .then(function (response) {
                onStart();
            })
            .catch(function (error) {
                toast.error("خطأ، برجاء المحاولة لاحقا!");
            });
    };

    return (
        <section>
            {loading && <Loader />}
            <div className="datatable">
                <DataTableExtensions columns={columns} data={slides}>
                    <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        direction="rtl"
                        striped
                    />
                </DataTableExtensions>
            </div>
        </section>
    );
};

export default SliderIndex;
