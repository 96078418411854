import React, { useEffect, useState } from "react";
import Loader from "../components/shared/Loader";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../store/actions/metaAction";
import { useDispatch } from "react-redux";
import { buttons } from "../helpers/data/settingsButtons";

const Settings = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [settings, setSettings] = useState([]);

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اعدادات الموقع",
				breadcrumb: "الاعدادات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/settings/main`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setSettings(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleChange = e => {
		let objIndex = settings.findIndex(obj => obj.name == e.target.name);
		let items = [...settings];
		items[objIndex].value = e.target.value;
		setSettings(items);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const newObj = (obj => {
			settings.forEach(({ name, value }) => {
				obj[name] = value;
			});
			return obj;
		})({});
		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/settings`, newObj, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				toast.success("تم التحديث بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	const handleSetting = type => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_API_URL}/settings/${type}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setSettings(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleChangeFile = name => {
		setLoading(true);
		const pictureTwo = document.querySelector(`#${name}`);
		const data = new FormData();
		data.append("file", pictureTwo.files[0]);
		axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				axios
					.post(
						`${process.env.REACT_APP_API_URL}/settings`,
						{
							appLogo: response.data.data.name,
						},
						{
							headers: {
								Authorization: `Bearer ${cookie.get("token")}`,
								Accept: "application/json",
							},
						}
					)
					.then(function (response) {
						toast.success("تم التحديث بنجاح!");
						setLoading(false);
					})
					.catch(function (error) {
						toast.error("خطأ، برجاء المحاولة مره اخرى!");
						setLoading(false);
					});
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<ul className="list-inline filterSettings">
				{buttons.map(button => (
					<li className="list-inline-item" key={button.id}>
						<button
							className="bt bt-success"
							onClick={() => handleSetting(button.link)}
						>
							{button.title}
						</button>
					</li>
				))}
			</ul>
			<form onSubmit={handleSubmit}>
				<div className="row">
					{loading === false &&
						settings.map(setting => (
							<div className="col-md-6" key={setting.name}>
								<div className="form-item">
									<label htmlFor={setting.name}>{setting.trans_name}</label>
									{setting.type === "textarea" ? (
										<textarea
											name={setting.name}
											id={setting.name}
											cols="30"
											rows="3"
											onChange={handleChange}
											value={setting.value}
										/>
									) : setting.type === "select" ? (
										<select
											name={setting.name}
											id={setting.name}
											value={setting.value}
											onChange={handleChange}
										>
											<option value="0">غير مفعل</option>
											<option value="1">مفعل</option>
										</select>
									) : setting.type === "file" ? (
										<input
											type="file"
											name={setting.name}
											onChange={() => handleChangeFile(setting.name)}
											id={setting.name}
										/>
									) : setting.type === "color" ? (
										<input
											type="color"
											name={setting.name}
											value={JSON.stringify(setting.value)}
											onChange={handleChange}
											id={setting.name}
										/>
									) : (
										<input
											type="text"
											name={setting.name}
											value={setting.value + ""}
											onChange={handleChange}
											id={setting.name}
										/>
									)}
								</div>
							</div>
						))}
				</div>
				<div className="row">
					<div className="col-md-12">
						<button className="bt bt-success">ارسال</button>
					</div>
				</div>
			</form>
		</section>
	);
};

export default Settings;
