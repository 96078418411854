import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";

const SectionsCreate = () => {
	const history = useHistory();
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/categories`,
				{ title: { ar: title, en: titleEn }, type_id: 4 },
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/sections/index");
				toast.success("تم اضافة التصنيف بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-6">
						<div className="form-item">
							<label htmlFor="title">اسم التصنيف بالعربية</label>
							<input
								type="text"
								id="title"
								required
								onChange={e => setTitle(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-item">
							<label htmlFor="title">اسم التصنيف بالإنجليزية</label>
							<input
								type="text"
								id="title-en"
								required
								onChange={e => setTitleEn(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="form-item">
					<button className="btn btn-success">ارسال</button>
				</div>
			</form>
		</section>
	);
};

export default SectionsCreate;
