import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const WorksIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع الأعمال",
				breadcrumb: "الأعمال",
				button: {
					show: true,
					text: "اضافة عمل جديد",
					link: "/works/create",
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/our_works/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setData(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
				setLoading(false);
			});
	};

	const handleDelete = postID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/posts/${postID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			{data.map(item =>
				item.categories.map(category => (
					<div key={category.id}>
						<h1 className="itemTitle">{category.title.ar}</h1>
						<div className="row">
							{category.posts.map(post => (
								<div key={post.id} className="col-md-2 col-6">
									<div className="serviceItem">
										<button onClick={() => handleDelete(post.id)}>
											<i className="fas fa-trash"/>
										</button>
										<Link to={`/works/show/${post.id}`}>
											<img
												src={`${process.env.REACT_APP_MAIN_URL}/${post.data.main_image}`}
												alt={post.data.title.ar}
											/>
											<h1>{post.data.title.ar}</h1>
										</Link>
									</div>
								</div>
							))}
						</div>
					</div>
				))
			)}
		</section>
	);
};

export default WorksIndex;
