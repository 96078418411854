import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const ContactShow = ({ match }) => {
	const {
		params: { contactID },
	} = match;

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تفاصيل الرسالة",
				breadcrumb: "اتصل بنا",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/contacts/${contactID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setData(response.data.data)
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="row">
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-6">
							<div className="form-item">
								<label htmlFor="name">الاسم الأول</label>
								<input type="text" id="name" disabled value={data.name} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-item">
								<label htmlFor="name">الاسم الأخير</label>
								<input type="text" id="name" disabled value={data.last_name} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-item">
								<label htmlFor="email">البريد الالكتروني</label>
								<input type="email" id="email" disabled value={data.email} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-item">
								<label htmlFor="phone">رقم الهاتف</label>
								<input type="text" id="phone" disabled value={data.phone} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-item">
								<label htmlFor="phone">نوع الخدمة</label>
								<input type="text" id="phone" disabled value={data.service_name} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-item">
								<label htmlFor="phone">مدة التعاقد</label>
								<input type="text" id="phone" disabled value={data.contract_period} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-item">
								<label htmlFor="phone">المهن المطلوبة</label>
								<input type="text" id="phone" disabled value={data.required_professions} />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-item">
								<label htmlFor="phone">عدد العمالة المطلوبة</label>
								<input type="text" id="phone" disabled value={data.employees_num} />
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-item">
								<label htmlFor="phone">الرسالة</label>
								<textarea rows={4} id="phone" disabled value={data.massage} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactShow;
