import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const CategoriesShow = ({ match }) => {
	const {
		params: { categoryID },
	} = match;

	const [name, setName] = useState("");
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل التصنيف",
				breadcrumb: "المدونة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/blog_categories/${categoryID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setName(response.data.data.name);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/blog_categories/${categoryID}`,
				{ name },
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث التصنيف بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-item">
							<label htmlFor="name">اسم التصنيف</label>
							<input
								type="text"
								id="name"
								required
								value={name}
								onChange={e => setName(e.target.value)}
							/>
						</div>
						<div className="form-item">
							<button className="btn btn-success">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default CategoriesShow;
