import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";

const SectionsShow = ({ match }) => {
	const {
		params: { sectionID },
	} = match;

	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/categories/${sectionID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setTitle(response.data.data.title.ar);
				setTitleEn(response.data.data.title.en);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/categories/${sectionID}`,
				{ title: { ar: title, en: titleEn } },
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث التصنيف بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-6">
						<div className="form-item">
							<label htmlFor="title">اسم التصنيف بالعربية</label>
							<input
								type="text"
								id="title"
								required
								value={title}
								onChange={e => setTitle(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-item">
							<label htmlFor="title">اسم التصنيف بالإنجليزية</label>
							<input
								type="text"
								id="title-en"
								required
								value={titleEn}
								onChange={e => setTitleEn(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="form-item">
					<button className="btn btn-success">ارسال</button>
				</div>
			</form>
		</section>
	);
};

export default SectionsShow;
