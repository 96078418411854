export const navbarItems = [
	{
		id: 1,
		link: "/",
		icon: "fas fa-home",
		title: "لوحة التحكم",
		children: [],
	},
	{
		id: 2,
		link: "/moderators/index",
		icon: "fas fa-user",
		title: "المشرفين",
		children: [
			{
				id: 3,
				title: "جميع المشرفين",
				link: "/moderators/index",
			},
			{
				id: 4,
				title: "اضافة مشرف جديد",
				link: "/moderators/create",
			},
		],
	},
	{
		id: 5,
		link: "/blog/index",
		icon: "fas fa-edit",
		title: "المدونة",
		children: [
			{
				id: 6,
				title: "جميع المقالات",
				link: "/blog/index",
			},
			{
				id: 7,
				title: "اضافة مقال جديد",
				link: "/blog/create",
			},
			{
				id: 8,
				title: "التصنيفات",
				link: "/categories/index",
			},
		],
	},
	{
		id: 9,
		link: "/services/index",
		icon: "fas fa-layer-group",
		title: "الخدمات",
		children: [
			{
				id: 10,
				title: "جميع الخدمات",
				link: "/services/index",
			},
			{
				id: 11,
				title: "اضافة خدمة جديدة",
				link: "/services/create",
			},
			{
				id: 12,
				title: "طلبات الخدمات",
				link: "/services/applications",
			},
		],
	},
	{
		id: 14,
		link: "/works/index",
		icon: "fas fa-images",
		title: "أعمالنا",
		children: [
			{
				id: 33,
				title: "التصنيفات",
				link: "/sections/index",
			},
			{
				id: 15,
				title: "جميع الأعمال",
				link: "/works/index",
			},
			{
				id: 16,
				title: "اضافة عمل جديد",
				link: "/works/create",
			},
		],
	},
	{
		id: 18,
		link: "/opinions/index",
		icon: "fas fa-question",
		title: "الأسئلة الشائعة",
		children: [
			{
				id: 19,
				title: "جميع الأسئلة",
				link: "/questions/index",
			},
			{
				id: 20,
				title: "اضافة سؤال جديد",
				link: "/questions/create",
			},
		],
	},
	{
		id: 21,
		link: "/partners/index",
		icon: "fas fa-handshake",
		title: "الشركاء",
		children: [
			{
				id: 22,
				title: "جميع الشركاء",
				link: "/partners/index",
			},
			{
				id: 23,
				title: "اضافة شريك جديدة",
				link: "/partners/create",
			},
		],
	},
	{
		id: 24,
		link: "/contact/index",
		icon: "fas fa-envelope",
		title: "اتصل بنا",
		children: [],
	},
	{
		id: 25,
		link: "/ceo/show",
		icon: "fas fa-id-card",
		title: "رسالة الرئيس التنفيذي",
		children: [],
	},
	{
		id: 27,
		link: "/slider/index",
		icon: "far fa-images",
		title: "سلايدر",
		children: [
			{
				id: 28,
				title: "جميع السلايدات",
				link: "/slider/index",
			},
			{
				id: 29,
				title: "اضافة سلايد جديد",
				link: "/slider/create",
			},
		],
	},
	{
		id: 30,
		link: "/newsletter/index",
		icon: "far fa-envelope",
		title: "القائمة البريدية",
		children: []
	},
	{
		id: 31,
		link: "/footer-links/index",
		icon: "fas fa-link",
		title: "روابط الفوتر",
		children: []
	},
	{
		id: 32,
		link: "/about/show",
		icon: "fas fa-info-circle",
		title: "نبذة عنا",
		children: []
	},
	{
		id: 34,
		link: "/steps/show",
		icon: "far fa-list-alt",
		title: "الخطوات",
		children: []
	}
];
