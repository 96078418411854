import { BrowserRouter as Router, Switch } from "react-router-dom";
import GeustRoute from "./helpers/authentication/geustRoute";
import Layout from "./components/layout/Layout";
import AuthRoute from "./helpers/authentication/authRoute";
import Login from "./views/authentication/Login";
import Home from "./views/Home";
import ModeratorsIndex from "./views/moderators/ModeratorsIndex";
import ModeratorsCreate from "./views/moderators/ModeratorsCreate";
import ModeratorShow from "./views/moderators/ModeratorShow";
import BlogIndex from "./views/blog/BlogIndex";
import BlogCreate from "./views/blog/BlogCreate";
import BlogShow from "./views/blog/BlogShow";
import CategoriesIndex from "./views/blog/categories/CategoriesIndex";
import CategoriesCreate from "./views/blog/categories/CategoriesCreate";
import CategoriesShow from "./views/blog/categories/CategoriesShow";
import ServicesIndex from "./views/services/ServicesIndex";
import ServicesCreate from "./views/services/ServicesCreate";
import ServiceShow from "./views/services/ServiceShow";
import ServicesApplications from "./views/services/ServicesApplications";
import ServiceShowApplication from "./views/services/ServiceShowApplication";
import WorksIndex from "./views/works/WorksIndex";
import WorksCreate from "./views/works/WorksCreate";
import WorkShow from "./views/works/WorkShow";
import QuestionsIndex from "./views/questions/QuestionsIndex";
import QuestionsCreate from "./views/questions/QuestionsCreate";
import QuestionsShow from "./views/questions/QuestionsShow";
import PartnersIndex from "./views/partners/PartnersIndex";
import PartnersCreate from "./views/partners/PartnersCreate";
import PartnersShow from "./views/partners/PartnersShow";
import ContactIndex from "./views/contact/ContactIndex";
import ContactShow from "./views/contact/ContactShow";
import SliderIndex from "./views/slider/SliderIndex";
import SliderCreate from "./views/slider/SliderCreate";
import SliderShow from "./views/slider/SliderShow";

import LinksIndex from "./views/footerLinks/LinksIndex";
import LinksCreate from "./views/footerLinks/LinksCreate";
import LinksShow from "./views/footerLinks/LinksShow";

import NewsletterIndex from "./views/newsletter/NewsletterIndex";
import NewsletterCreate from "./views/newsletter/NewsletterCreate";
import Ceo from "./views/ceo";
import Settings from "./views/Settings";
import About from "./views/About";
import SectionsIndex from "./views/works/sections/SectionsIndex";
import SectionsCreate from "./views/works/sections/SectionsCreate";
import SectionsShow from "./views/works/sections/SectionsShow";
import Steps from "./views/Steps";

function App() {
	return (
		<Router>
			<Switch>
				<GeustRoute path="/login" component={Login} />
				<Layout>
					<AuthRoute path="/" exact component={Home} />
					<AuthRoute path="/dashboard" component={Home} />
					<AuthRoute path="/moderators/index" component={ModeratorsIndex} />
					<AuthRoute path="/moderators/create" component={ModeratorsCreate} />
					<AuthRoute
						path="/moderators/show/:moderatorID"
						component={ModeratorShow}
					/>
					<AuthRoute path="/blog/index" component={BlogIndex} />
					<AuthRoute path="/blog/create" component={BlogCreate} />
					<AuthRoute path="/blog/show/:blogID" component={BlogShow} />
					<AuthRoute path="/categories/index" component={CategoriesIndex} />
					<AuthRoute path="/categories/create" component={CategoriesCreate} />
					<AuthRoute
						path="/categories/show/:categoryID"
						component={CategoriesShow}
					/>
					<AuthRoute path="/services/index" component={ServicesIndex} />
					<AuthRoute path="/services/create" component={ServicesCreate} />
					<AuthRoute path="/services/show/:serviceID" component={ServiceShow} />
					<AuthRoute
						path="/services/applications"
						component={ServicesApplications}
					/>
					<AuthRoute
						path="/services/application/:applicationID"
						component={ServiceShowApplication}
					/>
					<AuthRoute path="/works/index" component={WorksIndex} />
					<AuthRoute path="/works/create" component={WorksCreate} />
					<AuthRoute path="/works/show/:workID" component={WorkShow} />
					<AuthRoute path="/questions/index" component={QuestionsIndex} />
					<AuthRoute path="/questions/create" component={QuestionsCreate} />
					<AuthRoute
						path="/questions/show/:opinionID"
						component={QuestionsShow}
					/>
					<AuthRoute path="/partners/index" component={PartnersIndex} />
					<AuthRoute path="/partners/create" component={PartnersCreate} />
					<AuthRoute
						path="/partners/show/:partnerID"
						component={PartnersShow}
					/>
					<AuthRoute path="/contact/index" component={ContactIndex} />
					<AuthRoute path="/contact/show/:contactID" component={ContactShow} />
					<AuthRoute path="/newsletter/index" component={NewsletterIndex} />
					<AuthRoute path="/newsletter/send" component={NewsletterCreate} />
					<AuthRoute path="/ceo/show" component={Ceo} />
					<AuthRoute path="/about/show" component={About} />
					<AuthRoute path="/steps/show" component={Steps} />
					<AuthRoute path="/settings" component={Settings} />
					<AuthRoute path="/slider/index" component={SliderIndex} />
					<AuthRoute path="/slider/create" component={SliderCreate} />
					<AuthRoute path="/slider/show/:slideID" component={SliderShow} />
					<AuthRoute path="/footer-links/index" component={LinksIndex} />
					<AuthRoute path="/footer-links/create" component={LinksCreate} />
					<AuthRoute path="/footer-links/show/:linkID" component={LinksShow} />
					<AuthRoute path="/sections/index" component={SectionsIndex} />
					<AuthRoute path="/sections/create" component={SectionsCreate} />
					<AuthRoute
						path="/sections/show/:sectionID"
						component={SectionsShow}
					/>
				</Layout>
			</Switch>
		</Router>
	);
}

export default App;
