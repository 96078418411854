import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";

const QuestionsCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [question, setQuestion] = useState("");
	const [questionEn, setQuestionEn] = useState("");
	const [answer, setAnswer] = useState("");
	const [answerEn, setAnswerEn] = useState("");
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة سؤال جديد",
				breadcrumb: "الأسئلة الشائعة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		const data = {
			question: {
				ar: question,
				en: questionEn
			},
			answer: {
				ar: answer,
				en: answerEn
			}
		};
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/posts`,
				{
					admin_id: auth.id,
					category_id: 38,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/opinions/index");
				toast.success("تم اضافة الرأي بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="name">السؤال بالعربية</label>
											<input
												type="text"
												id="name"
												required
												onChange={e => setQuestion(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="name">السؤال بالانجليزية</label>
											<input
												type="text"
												id="name"
												required
												onChange={e => setQuestionEn(e.target.value)}
											/>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="position">الإجابة بالعربية</label>
											<textarea
												id="position"
												cols="30"
												rows="2"
												onChange={e => setAnswer(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="position">الإجابة بالإنجليزية</label>
											<textarea
												id="position"
												cols="30"
												rows="2"
												onChange={e => setAnswerEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="form-item mt-3">
									<button className="bt bt-success btn-block">ارسال</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default QuestionsCreate;
