import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const ContactIndex = () => {
	const [contacts, setContacts] = useState([]);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع الرسائل المرسلة من الزوار",
				breadcrumb: "اتصل بنا",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/contacts`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setContacts(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const columns = [
		{
			name: "ID",
			selector: "id",
			sortable: true,
		},
		{
			name: "الاسم",
			selector: "name",
			sortable: true,
		},
		{
			name: "البريد الالكتروني",
			selector: "email",
			sortable: true,
		},
		{
			name: "رقم الهاتف",
			selector: "phone",
			sortable: true,
		},
		{
			name: "settings",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						<Link
							className="bt bt-success bt-sm"
							to={`/contact/show/${row.id}`}
						>
							<i className="fas fa-eye"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-danger bt-sm"
							onClick={() => handleConfrimDelete(row.id)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfrimDelete = contactID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteContact(contactID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteContact = contactID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/contacts/${contactID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="datatable">
				<DataTableExtensions columns={columns} data={contacts}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
		</section>
	);
};

export default ContactIndex;
