import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { useDropzone } from "react-dropzone";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { updateMeta } from "../../store/actions/metaAction";

const WorksCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [organization, setOrganization] = useState("");
	const [organizationEn, setOrganizationEn] = useState("");
	const [country, setCountry] = useState("");
	const [countryEn, setCountryEn] = useState("");
	const [description, setDescription] = useState("");
	const [descriptionEn, setDescriptionEn] = useState("");
	const [plan, setPlan] = useState("");
	const [planEn, setPlanEn] = useState("");
	const [picture, setPicture] = useState(userPlaceholder);
	const [files, setFiles] = useState([]);
	const [imageArray, setImageArray] = useState([]);
	const [infoTitle, setInfoTitle] = useState("");
	const [infoTitleEn, setInfoTitleEn] = useState("");
	const [informations, setInformations] = useState([]);
	const [informationsEn, setInformationsEn] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(0);
	const dispatch = useDispatch();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	useEffect(
		() => () => {
			files.forEach(file => URL.revokeObjectURL(file.preview));
		},
		[files]
	);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة عمل جديد",
				breadcrumb: "الأعمال",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/type_categories/our_works`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setCategories(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const pushToInformation = e => {
		const randomId = Math.random().toString(36).substr(2, 9);
		e.preventDefault();
		if (infoTitle === "") {
			toast.error("برجاء تعبئة الحقول المطلوبة!");
			return;
		}
		const data = { id: randomId, title: infoTitle };
		informations.push(data);
		setInformations(informations);
		setInfoTitle("");
	};

	const handleDeleteInfo = (event, information) => {
		event.preventDefault();
		const arr = informations.filter(function (item) {
			return item.id !== information.id;
		});
		setInformations(arr);
	};

	const pushToInformationEn = e => {
		const randomId = Math.random().toString(36).substr(2, 9);
		e.preventDefault();
		if (infoTitleEn === "") {
			toast.error("برجاء تعبئة الحقول المطلوبة!");
			return;
		}
		const data = { id: randomId, title: infoTitleEn };
		informationsEn.push(data);
		setInformationsEn(informationsEn);
		setInfoTitleEn("");
	};

	const handleDeleteInfoEn = (event, information) => {
		event.preventDefault();
		const arr = informationsEn.filter(function (item) {
			return item.id !== information.id;
		});
		setInformationsEn(arr);
	};

	const handleImageUpload = async e => {
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					imageArray.push(responseImage.data.data.name);
					setImageArray(imageArray);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
		setFiles([]);
	};

	const handleDeleteImage = (event, image) => {
		event.preventDefault();
		const filteredImages = imageArray.filter(imageItem => imageItem !== image);
		setImageArray(filteredImages);
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (selectedCategory == 0) {
			toast.error("برجاء اختيار التصنيف اولا!");
			return;
		}
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					const data = {
						title: {
							ar: title,
							en: titleEn,
						},
						description: {
							ar: description,
							en: descriptionEn,
						},
						main_image: response.data.data.name,
						information: {
							ar: informations,
							en: informationsEn,
						},
						organization: {
							ar: organization,
							en: organizationEn
						},
						country: {
							ar: country,
							en: countryEn
						},
						plan: {
							ar: plan,
							en: planEn,
						},
						slider: imageArray,
					};
					axios
						.post(
							`${process.env.REACT_APP_API_URL}/posts`,
							{
								category_id: selectedCategory,
								admin_id: auth.id,
								data: data,
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							history.push("/works/index");
							toast.success("تم اضافة العمل بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">التصنيف</label>
									<select
										id="category"
										onChange={e => setSelectedCategory(e.target.value)}
									>
										<option value="0">اختر التصنيف</option>
										{categories.map(category => (
											<option value={category.id} key={category.id}>
												{category.title.ar}
											</option>
										))}
									</select>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">العنوان بالعربية</label>
											<input
												type="text"
												id="title"
												required
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title-en">العنوان بالانجليزية</label>
											<input
												type="text"
												id="title-en"
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">خطة العمل بالعربية</label>
											<textarea
												id="plan"
												required
												onChange={e => setPlan(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title-en">خطة العمل بالانجليزية</label>
											<textarea
												id="plan-en"
												onChange={e => setPlanEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">الجهة بالعربية</label>
											<input
												type="text"
												required
												onChange={e => setOrganization(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title-en">الجهة بالانجليزية</label>
											<input
												type="text"
												onChange={e => setOrganizationEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">البلد بالعربية</label>
											<input
												type="text"
												required
												onChange={e => setCountry(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title-en">البلد بالانجليزية</label>
											<input
												type="text"
												onChange={e => setCountryEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="form-item">
									<label htmlFor="description">الوصف بالعربية</label>
									<CKEditor
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDescription(data);
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
										}}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">الوصف بالانجليزية</label>
									<CKEditor
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDescriptionEn(data);
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "ltr",
											language: "en",
										}}
									/>
								</div>
								<div className="dropzoneContainer">
									<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} />
										<p>اضغط هنا لرفع الصور</p>
									</div>
									<aside className="thumbsContainer">{thumbs}</aside>
									<button onClick={handleImageUpload} className="bt bt-success">
										رفع الصور
									</button>
								</div>
								{imageArray.length > 0 && (
									<div className="dropzoneContainer mt-5 uploaded">
										<div className="thumbsContainer">
											{imageArray.map(image => (
												<div className="thumb" key={image}>
													<div className="thumbInner">
														<img
															src={`${process.env.REACT_APP_MAIN_URL}${image}`}
															alt={image}
														/>
														<button onClick={e => handleDeleteImage(e, image)}>
															<i className="fas fa-trash" />
														</button>
													</div>
												</div>
											))}
										</div>
									</div>
								)}
								<br />
								<hr />
								<br />
								<div className="form-item">
									<label htmlFor="question">عنوان المعلومة بالعربية</label>
									<input
										type="text"
										id="question"
										value={infoTitle}
										onChange={e => setInfoTitle(e.target.value)}
									/>
								</div>

								<div className="form-item" />
								<button className="bt bt-success" onClick={pushToInformation}>
									اضافة المعلومة
								</button>
								<div className="form-item" style={{ marginTop: "40px" }}>
									<ul className="list-unstyled pointList">
										{informations.map(information => (
											<li key={information.title}>
												<button onClick={e => handleDeleteInfo(e, information)}>
													<i className="fas fa-trash"></i>
												</button>
												<span>{information.title}</span>
											</li>
										))}
									</ul>
								</div>
								<br />
								<div className="form-item">
									<label htmlFor="question">عنوان المعلومة بالانجليزية</label>
									<input
										type="text"
										id="question"
										value={infoTitleEn}
										onChange={e => setInfoTitleEn(e.target.value)}
									/>
								</div>

								<div className="form-item" />
								<button className="bt bt-success" onClick={pushToInformationEn}>
									اضافة المعلومة
								</button>
								<div className="form-item" style={{ marginTop: "40px" }}>
									<ul className="list-unstyled pointList">
										{informationsEn.map(information => (
											<li key={information.title}>
												<button
													onClick={e => handleDeleteInfoEn(e, information)}
												>
													<i className="fas fa-trash"></i>
												</button>
												<span>{information.title}</span>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="picture" />
									<img src={picture} alt="avatar" className="imagePreview" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default WorksCreate;
